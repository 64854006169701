<template>
  <div class="mt-6 mx-3 mx-md-6">
    <v-card class="pa-3 pa-md-6 card">
      <v-form ref="form" v-model="valid">
        <v-card-text class="pa-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.old_password"
                :rules="passwordRules"
                :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                label="Old Password"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.password"
                :rules="passwordRules"
                :append-icon="show2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                label="New Password"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.password_confirmation"
                :rules="confirmPasswordRules"
                :append-icon="show3 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="show3 ? 'text' : 'password'"
                @click:append="show3 = !show3"
                label="Confirm New Password"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-0">
          <v-spacer></v-spacer>
          <v-btn @click="cancel" outlined class="primary--text">Cancel</v-btn>
          <v-btn :disabled="!valid || loading" :loading="loading" @click="updatePassword" class="primary">Save Changes</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import store from '../../store'
export default {
  data() {
    return {
      valid: true,
      user: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      show1: false,
      show2: false,
      show3: false,

      loading: false,

      passwordRules: [
        (v) => !!v || 'Please type password.',
        (v) => (v && v.length >= 6) || 'minimum 6 characters',
      ],
      confirmPasswordRules: [
        v => !!v || 'type confirm password',
        v => v === this.user.password || 'The password confirmation does not match'
      ],
    };
  },
  methods: {
    cancel() {
      // this.$refs.from.reset()
      this.user = {}
      this.$refs.form.resetValidation()
      this.$emit('cancel')
    },
    updatePassword() {
      this.loading = true
      // console.log(this.user.name, this.email)
      store.dispatch('updatePass', this.user)
      .then(res => {
        if(res.status > 201) {
          this.loading = false
          const snackbar = {
            success: false,
            snackbarColor: "#FF5252",
            snackbarText: res.data.message
          }
          this.$emit('updated', snackbar)
        } else {
          this.loading = false
          const snackbar = {
            success: true,
            snackbarColor: "#31A274",
            snackbarText: `${res.data.message}`
          }
          this.$emit('updated', snackbar)
        }
      })
    },
  },
};
</script>
